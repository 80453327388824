@use '@angular/material' as mat;
@import "theme-global-util";

.button.close {
  color: mat.get-color-from-palette($primary, 'A100');
}

.cancel-btn{
  color: var(--subtext) !important;
  border-color: var(--subtext) !important;
  width: 87.5px;
}

.save-btn{
  width: 87.5px;
}

.save-btn:not(.mat-button-disabled){
  background-color: var(--success) !important;
}

.delete-btn{
  width: 87.5px;
}

.delete-btn:not(.mat-button-disabled){
  background-color: mat.get-color-from-palette($warn) !important;
}

button.confirm-dialog{
  width: 107px!important;
}

.mat-stroked-button.mat-accent:not(.mat-button-disabled):not(.cancel-btn){
  border-color: var(--btn-secondary) !important;
}

.btn-container {
  text-align: center;
}

.btn-container > button {
  margin: 10px;
}
