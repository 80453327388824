.highcharts-tooltip.defaultTooltip {
  display: block;
  font-family: "Roboto";
  font-size: 16px;
  z-index: 10;
}

.highcharts-tooltip.defaultTooltip > span {
  padding: 0.7325em 0.5em;
  z-index: 10;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid var(--border);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
}

.highcharts-tooltip.defaultTooltip {
  .header {
    font-weight: bold;
    font-size: 0.75em;
  }
  .header .with-date {
    text-transform: uppercase;
  }

  .series-list {
    list-style: none;
    padding: 0em;
    margin: 0em;
    display: flex;
    flex-direction: column;
    display: block;
    width: 100%;
    line-height: 0px;
  }

  .serie-item {
    padding: 0em;
    margin: 0em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    line-height: 0px;
  }

  .serie-item {
    .name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      margin-right: 1.5em;
    }
    .name .avatar {
      height: 0.75em;
      width: 0.75em;
      margin-right: 0.75em;
      border-radius: 2px;
      display: block;
    }
    .value {
      color: var(--subtext);
      font-size: 0.875em;
      line-height: 20px;
    }
    @import "../../../../../../ui-components/src/format/template";
  }
}
