.mat-snack-bar-container.error {
  background: var(--danger);
}

.mat-snack-bar-container.success {
  background: var(--success);
}

.mat-snack-bar-container .custom {
  display: flex;
  justify-content: space-between;

  .icon {
    cursor: pointer;
  }

  span {
    white-space: pre-wrap;
  }
}

.mat-snack-bar-container.accept {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.2rem;

  padding: 1rem;

  background-color: var(--title);
  color: #ffffff;

}

.mat-snack-bar-container .accept-custom {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    white-space: pre-wrap;
    font-size: 0.8rem;
  }

  a {
    color: #ffffff;
    font-weight: 500;
  }

  button {
    color: #ffffff;
    background-color: var(--success);

    margin-left: 1rem;

    padding-top: 1.1rem;
    padding-bottom: 1.1rem;

    font-weight: 500;
    font-size: 1rem;

    width: 12.5rem;
    height: 2rem;

    border-radius: 5px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}