@use '@angular/material' as mat;
@import "base-variables";

.tag {
  display: inline-block;
  box-sizing: border-box;
  padding: 5px 15px;
  margin-left: 2px;
  border-radius: 20px;
  font-size: 0.875em;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
}

.tag .content {
  display: inline;
  word-break: break-word;
  overflow-wrap: break-word;
  line-break: normal;
  line-height: 20px;
}

.tag.primary {
  background-color: mat.get-color-from-palette($primary, A100);
}

.tag.primary.flat {
  background-color: transparent;
  color: mat.get-color-from-palette($primary, A100);
  border: 1px solid currentColor;
}

.tag.accent {
  background-color: mat.get-color-from-palette($accent, 900);
}

