.value {
  display: inline-flex;
}
.value {
  font-weight: normal;
}
.value {
  .measure {
    white-space: pre;
  }
  .digits {
    white-space: pre;
  }
}
.value.ltr {
  .measure {
    font-weight: bold;
  }
  .digits {
    font-weight: bold;
  }
}
.value.rtl {
  .digits {
    font-weight: bold;
  }
  .value.ltr .measure {
    font-weight: normal;
  }
}
