.info-panel {
  .icon {
    height: 100%;
    width: 2em;
    box-sizing: border-box;
    padding: 0.4em;
  }
  .icon.equivalence-trees {
    color: var(--success);;
  }
}
