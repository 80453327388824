  @use '@angular/material' as mat;
@use 'sass:map';

@mixin dg-topbar($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);
  // Extract the palettes you need from the theme definition.
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);

  .dg-topbar {
    display: block;
    width: 100%;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 5;
    background-color: mat.get-color-from-palette($primary, 'A100');
  }

  .mat-menu-item.version{
    height: 25px; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dg-topbar {
    nav {
      box-sizing: border-box;
      height: $height-topbar;
      width: 100%;
      padding: 0px 36px;
      background-color: mat.get-color-from-palette($primary, 'A100');
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--border);
    }

    .links {
      display: flex;
      align-items: center;
      align-content: center;
      height: 100%;
    }

    mat-icon {
      color: #fff;
    }

    .sidebar-button {
      margin-right: 33px;
      color: #fff;
      visibility: hidden;
    }

    .logo {
      height:40px;
      width: 80px;
      cursor: pointer;
    }

    .mat-tab-nav-bar,
    .mat-tab-links,
    .mat-tab-link {
      height: 100%;
    }

    .mat-tab-header{
      border-bottom: 0px;
    }

    .mat-tab-link-container{
      color: mat.get-color-from-palette($accent, '900');
    }

    .mat-tab-link {
      color: #fff;
      font-style: normal;
      font-weight: 400;
      opacity: 1;
      font-size: 1rem;
    }

    .mat-ink-bar{
      background-color: #fff!important;
    }

    .user-button {
      //margin: 10px 0px 0px auto;
      background-color: #fff;
      color: mat.get-color-from-palette($primary, 'A100');
    }

    .user-button {

      &.mat-fab:not([class*="mat-elevation-z"]),
      &.mat-mini-fab:not([class*="mat-elevation-z"]) {
        box-shadow: none;
      }
    }
  }

  small {
    padding: 10px;
    font-size: 0.8rem;
    font-weight: 600;
  }

  hr {
    border: 0.1px solid #eee;
    margin: 0;
    margin-bottom: 10px;
  }

  @media (max-width: 426px) {
    .dg-topbar nav {
      padding: 0px 15px;
    }
  }

  @media (max-width: 768px) {
    .logo {
      height: 50%;
      margin: 0 auto;
    }
  }
}