// always include only once per project
@use '@angular/material' as mat;
@include mat.core();

@import "./styles/custom-themes/aes-theme.scss";
@import "./styles/components/button.css";
@import "./styles/components/button.scss";
@import "./styles/components/tag.scss";
@import "./styles/components/tables.scss";
@import "./styles/variables.css";
@import "theme-global-util";

* {
  font-family: "Roboto";
  font-style: normal;
  //font-weight: normal;
}

html {
  color: var(--title);
}

body {
  background: var(--background);
  margin: 0px;
}

.loading-indicator {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 10000;
  height: 100%;
  width: 100%;
  background-image: url("/assets/ripple.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30%;
  background-color: rgba(250, 250, 250, 0.5);
}

.loading-indicator ~ header {
  padding-top: 20vh;
}

.loading-indicator header {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

mat-card {
  border-radius: 10px !important;
}

.page {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 36px 56px 36px;
}

.full-card {
  //width: 98%;
  width: 100%;
  min-width: 167px;
  min-height: 68px;
  //margin: 0% 1%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding-bottom: 10px;
  /*margin-top: 70px;*/
}

$padding: 20px;

.top-cards {
  display: grid;
  width: 100%;
  grid-column-gap: $padding;
}

.top-cards {
  &.two-cards {
    grid-template-columns: 1fr 1fr;
  }
}

.with-bottom-padding {
  margin-bottom: $padding;
}

.mat-checkbox-group {
  display: inline-block;
}

.mat-checkbox-group > * {
  margin-right: 50px;
}

.full-card h2 {
  padding: 25px;
}

.ga-panel table {
  width: 100%;
}

.card {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin: 15px;
  height: 80px;
}

.card-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--title);
  text-align: left;
}

.row {
  display: flex;
}

.sz-1 {
  width: 25%;
}

.sz-2 {
  width: 45%;
}

.cell-with-tag {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@import "app/dashboard/chart/shared/chart.scss";
@import "./styles/components/info-panel.scss";

.ga-dialog-title {
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 33px;
  color: var(--title);
  margin: 0px;
}

.ga-dialog-subtitle {
  font-weight: 700;
  font-size: 1rem;
  line-height: 18.75px;
  color: var(--subtext);
  padding: 0px;
}

.ga-dialog-title ~ .sub {
  margin-top: 5px;
  margin-bottom: 10px;
  color: $text-disabled;
  font-size: 16px;
}

.ga-new-dialog-title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--title);
  margin: 0px;
}

.ga-new-dialog-title ~ .sub {
  color: #737373;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;

}



.mat-dialog-actions {
  $material-padding: 8px;
  margin-top: 30px - $material-padding !important;
  justify-content: center;
}

.mat-dialog-container {
  padding: 24px !important;
  border-radius: 10px !important;
}

.no-padding-dialog .mat-dialog-container {
  padding: 0 !important;
	border-radius: 10px !important;
	box-shadow: 0 4px 8px 0 rgba(212, 212, 212, 0.5) !important;
}

/*************/
/** SIDENAV **/
/*************/
mat-sidenav-container,
mat-sidenav,
mat-sidenav-content {
  height: 100%;
}

mat-sidenav {
  background-color: #fff;
  width: 250px;
}

mat-nav-list {
  padding: 0 !important;
  .title {
    height: 30vh;
    background: mat.get-color-from-palette($primary, 'A100');
  }
  a {
    span {
      padding-left: 10px;
    }
  }
}

.mat-icon.more-vert{
  color: var(--subtext);
}

.icons {
  font-size: 21px;
  vertical-align: bottom;
}

.check-icon {
  color: var(--success);
}

.error-icon {
  color: var(--danger);
}

.time-icon {
  color: $shadow-color;
}

.dropzone{
  height: 200px!important;
  margin: 20px 0 0 0!important;
}

.mat-icon-button:not(.mat-button-disabled) .mat-paginator-icon{
  color: var(--subtext);
}

.mat-icon-button.mat-button-disabled .mat-paginator-icon{
  color: var(--disabled);
}

.mat-progress-bar-primary.mat-progress-bar-fill::after{
  background-color: var(--secondary) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element{
  background-color: mat.get-color-from-palette($primary)!important; /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: mat.get-color-from-palette($primary); /*outer ring color change*/
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  color:#fff;
}

.cdk-overlay-pane{
  max-height: 90vh!important;
}

.mat-chip.mat-standard-chip{
  background-color: var(--background);
  color: var(--subtext);
}

.table-filter-field {
  width: 464px;
  font-size: 1em;
}

.table-filter-field {
  .mat-form-field-prefix {
    color: var(--placeholder);
    font-size: 0.625em;
  }
  .mat-input-element::placeholder {
    color: var(--placeholder);
    font-size: 1em;
  }

}

.table-filter-field.mat-form-field.mat-focused .mat-form-field-label{
  display: none;
}

.mat-dialog-container p{
  color: var(--subtext);
}

.mat-form-field-subscript-wrapper .ng-trigger-transitionMessages{
  display: grid;
  ng-component{
    display: contents;
  }
}