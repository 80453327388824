:root {
  --background-color: #E5E5E5;
  --cancel-color: #808080;
  
  --danger: #D73A3A;
  --warn: #FFC72C;
  --success: #33CC99;
  --title: #333333;
  --subtext: #666666;
  --placeholder: #9E9E9E;
  --disabled: #BDBDBD;
  --border: #E0E0E0;
  --background: #F2F2F2;
}

