@use '@angular/material' as mat;
@use 'sass:map';
// Import library functions for theme creation.

@mixin dg-panel-outlet($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);
  // Extract the palettes you need from the theme definition.
  $primary: map.get($config, primary);

  .dg-panel-outlet {
    display: block;
    position: sticky;
    top: $height-topbar;
    z-index: 5;
  }

  .panel-header {
    padding: 20px 35px 0px 35px;
    background-color: mat.get-color-from-palette($primary, 'A100');
    transition: padding-bottom 0.3s;
    color: #fff;
  }
  .panel-header.no-simbling {
    padding: 20px 35px 20px 35px;
  }

  .dg-panel-outlet .mat-card {
    border-radius: 10px !important;
  }

  .dg-panel-outlet .row .mat-card:first-child {
    margin-left: 0px;
  }

  .dg-panel-outlet .row .mat-card:last-child {
    margin-right: 0px;
  }

  .dg-panel-outlet [panel-content] {
    background-color: mat.get-color-from-palette($primary, 'A100');
    box-sizing: border-box;
  }

  .toggle-down {
    transform: rotate(270deg);
    padding: 60px 0px;
    font-size: small;
    margin-top: 7px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 0px 5px 5px 0px;
  }

  .mat-expanded .toggle-down {
    transform: rotate(90deg);
    border-radius: 5px 0px 0px 5px;
  }

  .dg-panel-outlet .mat-expansion-panel {
    display: flex !important;
    flex-direction: column;
    border-radius: 0px !important;
    background-color: mat.get-color-from-palette($primary, 'A100');
    width: 100%;
    z-index: 100;
  }

  .dg-panel-outlet .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none;
  }

  .dg-panel-outlet .mat-expansion-panel-header {
    height: 20px !important;
    order: 2;
  }
  .dg-panel-outlet .mat-expansion-panel-header-title {
    text-align: center;
    display: block !important;
  }

  .dg-panel-outlet .mat-expansion-indicator {
    display: none;
  }
}
