.highcharts-data-label.defaultDataLabel {
  z-index: 5;
  font-size: 16px !important;
}

.highcharts-data-label.defaultDataLabel > span {
  z-index: 5;
}

.highcharts-data-label.defaultDataLabel {
  .value {
    color: #fff;
    font-size: 0.75em;
    line-height: 20px;
  }
  @import "../../../../../../ui-components/src/format/template";
}

.highcharts-data-label.alwaysTooltip {
  display: block;
  font-family: "Roboto";
  font-size: 16px;
  z-index: 10;
}

.highcharts-data-label.alwaysTooltip > span {
  padding: 0.3125em 0.6875em;
  z-index: 10;
  background-color: #364941;
  border-radius: 5px;
  border: 1px solid var(--border);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
}

$triangle-height: 10px;

.highcharts-data-label.alwaysTooltip > span::after,
.highcharts-data-label.alwaysTooltip > span::before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  clip-path: polygon(50% 67%, 0 100%, 100% 100%);
}

.highcharts-data-label.alwaysTooltip > span::after {
  top: -($triangle-height) * 2;
  left: calc(50% - #{$triangle-height});
  border-color: transparent transparent #364941 transparent;
  border-width: $triangle-height;
}

.highcharts-data-label.alwaysTooltip > span::before {
  top: -($triangle-height + 2) * 2;
  left: calc(50% - #{$triangle-height} - 1px);
  border-color: transparent transparent var(--border) transparent;
  border-width: $triangle-height + 1;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.08);
}

.highcharts-data-label.alwaysTooltip {
  .header {
    font-weight: bold;
    font-size: 0.75em;
  }
  .header .with-date {
    text-transform: uppercase;
  }

  .series-list {
    list-style: none;
    padding: 0em;
    margin: 0em;
    display: flex;
    flex-direction: column;
    display: block;
    width: 100%;
    line-height: 0px;
  }

  .serie-item {
    padding: 0em;
    margin: 0em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    line-height: 0px;
  }

  .serie-item {
    .name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      margin-right: 1.5em;
    }
    .name .avatar {
      height: 0.75em;
      width: 0.75em;
      margin-right: 0.75em;
      border-radius: 2px;
      display: block;
    }
    .value {
      color: #fff;
      font-size: 0.875em;
      line-height: 20px;
    }
    @import "../../../../../../ui-components/src/format/template";
  }
}
