/* For use in src/lib/core/theming/_palette.scss */
@use '@angular/material' as mat;

$md-primary: (
  50: #e0e5e7,
  100: #b3bec4,
  200: #80939d,
  300: #4d6875,
  400: #264758,
  500: var(--btn-primary),
  600: #002334,
  700: #001d2c,
  800: #001725,
  900: #000e18,
  A100: var(--primary),
  //A100: #5898ff,
  A200: #2579ff,
  A400: #005df1,
  A700: #0053d7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-accent: (
  50: #f2f7e4,
  100: #e0ecbc,
  200: #cbe08f,
  300: #b6d362,
  400: #a6c941,
  500: var(--btn-secondary),
  600: #8eba1b,
  700: #83b217,
  800: #79aa12,
  900: var(--secondary),
  A100: #eaffc9,
  A200: #d5ff96,
  A400: #c1ff63,
  A700: #b7ff4a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-warn: (
  50: #f9eded,
  100: #f0d1d1,
  200: #e6b3b3,
  300: #db9494,
  400: #d47d7d,
  500: var(--danger),
  600: #c75e5e,
  700: #c05353,
  800: #b94949,
  900: #ad3838,
  A100: #fff9f9,
  A200: #ffc6c6,
  A400: #ff9393,
  A700: #ff7a7a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$aes-primary: mat.define-palette($md-primary);
$aes-accent: mat.define-palette($md-accent);
$aes-warn: mat.define-palette($md-warn);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $aes-primary,
      accent: $aes-accent,
      warn: $aes-warn,
    ),
  )
);

$shadow-color: #cccccc;
$text-disabled: var(--disabled);
$font-weigth-bold: 500;
$height-topbar: 56px;
$border-radius: 15px;

$media-query-md-width: 768px;
$media-query-xl-width: 1440px;
