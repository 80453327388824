.mat-cell{
    color: var(--subtext)!important;
}

.mat-footer-cell{
    color: var(--subtext);
}

.name-column{
    font-weight: 500;
}

.align-right{
    text-align: end!important;
}

.mat-sort-header.align-right .mat-sort-header-container{
    justify-content: flex-end;
}

td.align-right{
    padding-right: 18px!important;
}

td.mat-column-expandedDetail{
    border-bottom-width: 0px !important;
}